$size: 8px;

/* width */
::-webkit-scrollbar {
  width: $size;
  height: $size;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: calc($size / 2);
}

::-webkit-scrollbar-corner {
  background: none;
}
