@media only screen and (max-width: 1200px) {
  .CommonContentRight {
    min-width: 200px;
    max-width: 200px;
    width: 200px;
  }
}

@media only screen and (min-width: 1200px) {
  .CommonContentRight {
    min-width: 270px;
    max-width: 270px;
    width: 270px;
  }
}
