.Backdrop {
  background: var(--gray-1);
}

.rt-Button {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &.btn-block {
    width: 100%;
  }
}

.rt-BaseButton {
  cursor: pointer;

  &.btn-floating {
    transition: background-color 300ms ease-in-out;

    &:not(:hover) {
      background-color: rgba($color: #000000, $alpha: 0);
    }
  }
}

.rounded-sm {
  border-radius: var(--radius-2);
  padding: var(--space-2);
}

.rounded-md {
  border-radius: var(--radius-4);
  padding: var(--space-4);
}

.rounded-lg {
  border-radius: var(--radius-5);
  padding: var(--space-5);
}

.MainSidebar {
  transition: width 300ms;

  &.full {
    width: 252px;
  }

  &.mini {
    width: 84px;
  }
}
