.DataCard {
  padding: var(--space-2) var(--space-3);
  height: 100%;
  &.rt-Card {
    &:where(.rt-variant-classic)::after {
      box-shadow: unset;
    }
    border-radius: var(--radius-3);
  }
}
