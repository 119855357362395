$padding-unit: 0.5em;

.Logs {
  .entries {
    border-radius: $padding-unit/2;
    padding-left: $padding-unit * 2;
    padding-right: $padding-unit;
    padding-top: $padding-unit;
    padding-bottom: $padding-unit * 2;
    background-color: rgba(255, 255, 255, 0.1);
    resize: vertical;
    overflow-y: auto;
    min-height: 100px;
    height: 150px;

    // trick browser into keeping the contents focused on bottom as new logs are added
    display: flex;
    flex-direction: column-reverse;

    ol {
      counter-reset: zero-indexed-counter -1;

      li {
        counter-increment: zero-indexed-counter;

        &::marker {
          content: '[' counter(zero-indexed-counter) '] ';
        }
      }
    }
  }
}
