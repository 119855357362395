// ensures that contained stuff like tables will be able to flex the full height
.rt-VertFlexWrapper {
  height: 100%;
  overflow: hidden;
}

.rt-VertFlexColumn {
  // this element will dictate the maximum space available to flex into
  display: flex;
  flex-flow: column;

  .rt-VertFlexGrow {
    // this element will flex to take up the available space vertically
    flex: 1;
  }
}

.DebugPurple {
  border: 2px solid purple;
  // border-style: inset;
}

.DebugGreen {
  border: 2px solid green;
  // border-style: inset;
}

.DebugYellow {
  border: 2px solid yellow;
  // border-style: inset;
}

.DebugRed {
  border: 2px solid red;
  // border-style: inset;
}
