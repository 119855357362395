#ThemeWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.radix-themes {
  --default-font-family: Inter, -apple-system, BlinkMacSystemFont,
    'Segoe UI (Custom)', 'Helvetica Neue', 'Open Sans (Custom)', system-ui,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  --heading-font-family: Inter, -apple-system, BlinkMacSystemFont,
    'Segoe UI (Custom)', 'Helvetica Neue', 'Open Sans (Custom)', system-ui,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  --strong-font-family: Inter, -apple-system, BlinkMacSystemFont,
    'Segoe UI (Custom)', 'Helvetica Neue', 'Open Sans (Custom)', system-ui,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  --em-font-family: Inter, -apple-system, BlinkMacSystemFont,
    'Segoe UI (Custom)', 'Helvetica Neue', 'Open Sans (Custom)', system-ui,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  --quote-font-family: Inter, -apple-system, BlinkMacSystemFont,
    'Segoe UI (Custom)', 'Helvetica Neue', 'Open Sans (Custom)', system-ui,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';

  --code-font-family: Menlo, 'Consolas (Custom)', 'Bitstream Vera Sans Mono',
    monospace, 'Apple Color Emoji', 'Segoe UI Emoji';

  --default-font-size: 14px;

  --popover-content-padding: var(--space-2);

  --radix-scroll-area-thumb-width: 8px;
  --radix-scroll-area-thumb-height: 8px;
}

:is(.light, .light-theme) :where(.radix-themes:not(.dark, .dark-theme)) {
  .rt-Card {
    --card-background-color: var(--gray-2);

    &.no-background {
      --card-background-color: none;
    }

    &.half-background {
      --card-background-color: var(--white-a6);
    }
  }
}

.rt-ScrollAreaThumb {
  // avoid table headers covering up the thumbs
  z-index: 2;
  // half of height and width
  border-radius: 4px;
}
