.ToastViewport {
  --viewport-padding: var(--space-2);
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: var(--viewport-padding);
  gap: var(--space-3);
  width: 390px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  outline: none;
  z-index: 2147483647;

  &.top-right {
    top: 0;
    right: 0;
  }

  &.bottom-right {
    bottom: 0;
    right: 0;
  }

  &.top-left {
    top: 0;
    left: 0;
  }

  &.bottom-left {
    bottom: 0;
    left: 0;
  }

  .ToastRoot {
    &[data-state='open'] {
      animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
    }

    &[data-state='closed'] {
      animation: hide 100ms ease-in;
    }

    &[data-swipe='move'] {
      transform: translateX(var(--radix-toast-swipe-move-x));
    }

    &[data-swipe='cancel'] {
      transform: translateX(0);
      transition: transform 200ms ease-out;
    }

    &[data-swipe='end'] {
      animation: swipeOut 100ms ease-out;
    }
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes swipeOut {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
}
