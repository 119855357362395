.CommonDetailsIndicator {
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);

  &[data-open='true'] {
    transform: rotate(180deg);
  }
}

.CommonDetailsContent {
  opacity: 0;
  visibility: hidden;

  transition:
    opacity 300ms,
    visibility 300ms,
    max-height 300ms;

  &[data-open='true'] {
    opacity: 1;
    visibility: visible;
  }
}
