.hidden {
  display: none;
}

.select-all {
  // click to select all text
  user-select: all;
  -moz-user-select: all;
  -webkit-user-select: all;
}

.select-none {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@media only screen and (max-width: 576px) {
  .hidden-xs {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .hidden-sm {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .hidden-md {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .hidden-lg {
    display: none;
  }
}

.plain-image {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
  shape-margin: 0.75rem;
}

details {
  &[open] {
    .while-closed {
      display: none;
    }

    .while-open {
      display: block;
    }
  }

  &:not([open]) {
    .while-closed {
      display: block;
    }

    .while-open {
      display: none;
    }
  }

  summary {
    cursor: pointer;
    color: var(--accent-10);
    margin-bottom: var(--space-2);

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
