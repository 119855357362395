.rt-CalloutRoot {
  display: flex;

  .rt-CalloutIcon {
    margin-top: -2px;
  }

  .rt-CalloutText {
    margin: 0;
  }

  .text-content,
  .md-content {
    p:last-child {
      margin: 0;
    }
  }

  &:where(.rt-variant-outline) {
    // default is transparent (and useless)
    background-color: var(--accent-3);
  }
}
