$overlayZ: 2; // to appear above table headers

.DialogContent,
.DialogOverlay {
  // share the same z-index => subsequent dialog overlays will cover pre-existing dialogs
  z-index: $overlayZ;
}

.DialogOverlay {
  background-color: var(--black-a9);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.DialogContent {
  background-color: var(--gray-2);
  border-radius: var(--radius-5);
  box-shadow: var(--shadow-6);
  padding: var(--space-5);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  &:focus {
    outline: none;
  }
}

.DialogTitle {
  margin: 0;

  :focus {
    outline: none;
  }
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
