.slider {
  .sizer {
    overflow: visible;
    // this div ensures all contents have the same dimensions
    position: relative;
    background-size: cover;
    background-position: center;
    margin: 0 auto;

    .slider-wrapper,
    canvas {
      // this locks both the slider and the canvas to be the same size
      // this ensures that dots position correctly w/ the slider thumb
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}

// use these rules with media queries if we need to resize based on screen width
// $ballSize: 28px;
// .slider-wrapper {
//   > div {
//     :last-child {
//       > div {
//         // the slider tool assigns a somewhat randomized hash to the CSS class based on the style choices
//         // making it unreliable to reference directly in this file
//         // height: $ballSize;
//         // width: $ballSize;
//         // margin: -$ballSize / 2;
//       }
//     }
//   }
// }
