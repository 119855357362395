.CameraStream {
  .sizer {
    position: relative;
    overflow: hidden;

    .blinker {
      position: absolute;
      top: var(--space-1);
      left: var(--space-1);

      svg {
        margin-top: -2px;
      }
    }

    .timestamp {
      position: absolute;
      top: var(--space-1);
      right: var(--space-1);
      opacity: 25%;

      &:hover {
        opacity: 100%;
      }
    }

    .camera-feed-wide {
      border-radius: var(--radius-2);
      object-fit: cover;
      aspect-ratio: 4;
      width: 100%;
    }
  }
}
