.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 50%;

  &:hover {
    opacity: 100%;
  }
}
