.capitalize {
  text-transform: capitalize;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-titlecase {
  // aka: titlecase
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

input:placeholder-shown {
  // ignore upper/lower transform for placeholder text
  text-transform: none;
}

table {
  th,
  td {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
