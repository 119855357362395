// because radix vars aren't available here for whatever reason
$space-2: 8px;
$space-3: 12px;

.rt-BaseMenuViewport {
  // so that context menus look the same as dropdown menus
  padding: 0;
}

.MenuGroupLabel {
  text-transform: uppercase;
  font-size: 10px;
}

.MenuContent,
.MenuSubContent {
  background-color: var(--gray-2);
  margin: $space-3 0;
  min-width: 120px;
  max-width: 400px;
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 6px;
  border: 1px solid var(--gray-6);
  padding: $space-2;
  box-shadow: var(--shadow-6);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}

.MenuContent[data-side='top'],
.MenuSubContent[data-side='top'] {
  animation-name: slideDownAndFade;
}
.MenuContent[data-side='right'],
.MenuSubContent[data-side='right'] {
  animation-name: slideLeftAndFade;
}
.MenuContent[data-side='bottom'],
.MenuSubContent[data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.MenuContent[data-side='left'],
.MenuSubContent[data-side='left'] {
  animation-name: slideRightAndFade;
}

.MenuItem {
  padding: 0 $space-3;
  min-height: 32px;

  line-height: 1;
  display: flex;
  align-items: center;
  border-radius: 3px;
  position: relative;
  user-select: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: var(--gray-3);
    color: var(--gray-12);
  }
}

.MenuItemSlot {
  margin-left: auto;
}

.MenuSeparator {
  background-color: var(--gray-a6);
  height: 1px;
  margin: $space-2 $space-3;
}

small {
  // for stuff like BETA indicators
  color: var(--gray-11);
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
