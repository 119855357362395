.AccordionRoot {
  .AccordionItem {
    border-radius: var(--radius-2);
    overflow: auto;

    &:not(:first-child) {
      margin-top: var(--space-2);
    }

    &:focus-within {
      position: relative;
      z-index: 1;
    }

    .AccordionHeader {
      &:hover {
        color: var(--accent-11);
      }

      .AccordionChevron {
        transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
      }

      &[data-state='open'] .AccordionChevron {
        transform: rotate(180deg);
      }
    }

    .AccordionContent {
      border-top: 1px solid var(--gray-6);

      &[data-state='open'] {
        animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
      }

      &[data-state='closed'] {
        animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
      }
    }
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
