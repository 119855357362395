.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.valign-center {
  margin-top: auto;
  margin-bottom: auto;
}

.valign-top {
  margin-top: 0;
  margin-bottom: auto;
}

.valign-bottom {
  margin-top: auto;
  margin-bottom: 0;
}

// has implications for tooltips, popovers, form hints, settings descriptions, etc...
p {
  margin: 6px 0 0 0;

  &:first-child {
    margin-top: 0;
  }
}

$sizes: 40px, 60px, 80px, 100px, 120px, 140px, 160px, 180px, 200px, 220px, 240px,
  260px, 280px, 300px;

@each $size in $sizes {
  .width-#{$size} {
    width: $size;
  }

  .min-width-#{$size} {
    min-width: $size;
  }

  .max-width-#{$size} {
    max-width: $size;
  }

  .height-#{$size} {
    height: $size;
  }

  .min-height-#{$size} {
    min-height: $size;
  }

  .max-height-#{$size} {
    max-height: $size;
  }
}

.width-100p {
  width: 100%;
}

.height-100p {
  height: 100%;
}

.block {
  display: block;
}

.img-block {
  width: 100%;
  height: auto;
}

.no-padding {
  padding: 0;
}

.less-padding {
  padding: 0.5rem;
}

.more-padding {
  padding: 2rem;
}

.no-margin {
  margin: 0;
}

.less-margin {
  margin: 0.5rem;
}

pre {
  white-space: pre-wrap;
}
