@keyframes fadeInOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-fade {
  &:hover {
    animation-name: fadeInOut;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-delay: 1500ms;
  }
}

@keyframes blinkAnimation {
  50% {
    opacity: 0;
  }
}

.animate-blink {
  animation-name: blinkAnimation;
  animation-duration: 1000ms;
  animation-timing-function: steps(1);
  animation-iteration-count: infinite;
}

.animate-background {
  transition: background-color 300ms ease-in-out;
}
