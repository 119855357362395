.FullMenu {
  .Entry {
    border-radius: var(--radius-4);

    &:hover {
      background-color: var(--gray-a2);
    }
  }

  details.FolderDetails {
    .FolderIcon {
      color: var(--gray-10);
      width: 20px;
      height: 20px;

      div {
        // lottie inserts an extra div
        width: 20px;
        height: 20px;
      }

      svg {
        width: 20px;
        height: 20px;
        aspect-ratio: 1;
      }
    }

    summary {
      color: unset;
      margin-bottom: unset;
    }

    > summary {
      // hide the default marker
      list-style: none;
    }

    > summary::-webkit-details-marker {
      // hide the default marker
      display: none;
    }
  }
}
