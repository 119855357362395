$header-bg: var(--gray-4);

.rt-TablePlaceholder {
  border-radius: var(--space-2);
  border: 1px solid var(--gray-6);

  justify-content: center;
  align-content: center;
}

.rt-TableRootTable {
  .rt-TableHeader {
    position: sticky;
    top: 0;
    // prevent table body checkboxes from obscuring while scrolling
    z-index: 1;

    .rt-TableRow {
      border-top: none;

      .rt-TableCell {
        background-color: $header-bg;
      }
    }
  }

  .rt-TableBody {
    .rt-TableRow {
      &:hover,
      &[data-context-menu='true'] {
        // hovered
        background-color: var(--gray-a3);
      }

      &[data-checked='true'] {
        // checked
        background-color: var(--gray-a4);
      }

      &[data-checked='true']:hover {
        // checked & hovered
        background-color: var(--gray-a5);
      }

      &[data-active='true'] {
        // focused
        background-color: var(--accent-a3);

        &:hover {
          // focused & hovered
          background-color: var(--accent-a4);
        }

        &[data-checked='true'] {
          // focused & checked
          background-color: var(--accent-a5);
        }

        &[data-checked='true']:hover {
          // focused & checked & hovered
          background-color: var(--accent-a6);
        }
      }
    }
  }

  .rt-TableCell {
    padding-top: 0;
    padding-bottom: 0;
    vertical-align: middle;
    max-width: 200px;
    overflow: hidden;

    .rt-Badge {
      // pushes the badge up to align it better
      margin-bottom: 4px;
    }

    .StrikeZoneIcon {
      margin-top: 4px;
    }
  }

  tfoot {
    background-color: $header-bg !important;
  }
}
