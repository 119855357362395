.cursor-pointer {
  cursor: pointer;

  .rt-TextFieldInput,
  .rt-TextFieldSlot {
    cursor: pointer;
  }
}

.cursor-not-allowed {
  cursor: not-allowed;

  .rt-TextFieldInput,
  .rt-TextFieldSlot {
    cursor: not-allowed;
  }
}

.cursor-help {
  cursor: help;

  .rt-TextFieldInput,
  .rt-TextFieldSlot {
    cursor: help;
  }
}
