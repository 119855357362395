.NavigationMenuRoot {
  z-index: 1;

  .NavigationMenuList {
    list-style: none;
    padding: 0;
    margin: 0;

    .NavigationMenuTrigger {
      text-align: start;
      width: 100%;

      padding-inline-start: 0;
      padding-inline-end: 0;

      // fix for ipads to not render as accent color by default
      color: var(--gray-12);
      cursor: pointer;
      border: none;
      background: none;
      user-select: none;
      border-radius: var(--space-2);
      text-wrap: nowrap;

      &.active {
        color: var(--accent-11);
        background-color: var(--accent-3);

        &:hover:not(:disabled) {
          background-color: var(--accent-4);
        }
      }

      &:hover:not(:disabled) {
        background-color: var(--gray-a2);
      }

      .NavigationMenuIcon {
        display: flex;
        justify-content: center;
        align-items: center;

        .rt-Text {
          height: 20px;

          svg {
            width: 20px;
            height: 20px;
            aspect-ratio: 1;
          }

          &.rt-r-size-1 {
            height: 16px;

            svg {
              width: 16px;
              height: 16px;
              aspect-ratio: 1;
            }
          }
        }
      }
    }
  }
}
