.MachineView {
  overflow: hidden;

  .sizer {
    position: relative;
    aspect-ratio: 16/9;
    height: 400px;
    margin: 0 auto; //center horizontally

    .slider-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
