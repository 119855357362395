.ToastWrapper {
  img {
    // for error user messages involving embedded images
    border-radius: 5px;
    height: auto;
    overflow: hidden;
    width: 100%;
  }

  .hover-wrapper {
    cursor: help;
    width: 100%;

    .hover-show {
      display: none;
    }

    .hover-hide {
      display: block;
    }

    &:hover {
      .hover-show {
        display: block;
      }

      .hover-hide {
        display: none;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  p {
    margin: 0;
  }

  ul,
  ol {
    padding: 0 1.5em;
  }

  img {
    margin: 1rem 0;
  }
}
