$thumb-size: var(--space-3);

.DualSlider {
  display: flex;
  align-items: center;
  position: relative;

  .DualSliderTrack {
    position: relative;
    flex: 1;
    border-radius: 9999px;
    height: var(--space-2);
    background-color: var(--gray-a3);
  }

  .DualSliderRange {
    position: absolute;
    background-color: var(--accent-9);
    border-radius: 9999px;
    height: 100%;
  }

  .DualSliderThumb {
    display: block;
    height: $thumb-size;
    width: $thumb-size;
    background-color: white;
    border-radius: var(--radius-2);
    border: solid 1px var(--gray-7);

    &:hover {
      cursor: grab;
    }
  }
}
